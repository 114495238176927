import React from "react";
import { Spinner } from "components/Core/Spinner/Spinner";

export const PageSpinner = (): React.ReactElement => {
  return (
    <Spinner
      size="large"
      color="accent"
      style={{
        paddingVertical: "30vh",
        margin: "auto",
      }}
    />
  );
};
