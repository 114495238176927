import ResponsiveStyleSheet from "react-native-media-query";
import { createResponsiveStyles, theme } from "@clearabee/ui-library-native";

export const { styles, ids } = ResponsiveStyleSheet.create({
  benefits: {
    justifyContent: "space-around",
    marginTop: theme.spacing.xlarge,
  },
  benefit: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginVertical: theme.spacing.small,
  },
  benefitText: {
    width: 220,
    marginBottom: theme.spacing.small,
    marginLeft: theme.spacing.medium,
  },
  layout: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  benefitsView: {
    [`@media (max-width: ${theme.screens.large}px)`]: {
      display: "none",
    },
  },
  panel: createResponsiveStyles({
    base: {
      backgroundColor: theme.colors.brand.dark,
      padding: theme.spacing.large,
      width: "100%",
    },
    small: {
      paddingHorizontal: theme.spacing.xlarge5,
      paddingVertical: theme.spacing.xlarge3,
    },
    large: {
      padding: theme.spacing.xlarge3,
      width: "50%",
    },
    xlarge: {
      paddingHorizontal: theme.spacing.xlarge5,
      paddingVertical: theme.spacing.xlarge4,
      width: "45%",
    },
  }),
  subtitle: {
    textAlign: "center",
    fontFamily: "ProximaBold",
    marginBottom: theme.spacing.medium,
  },
});
