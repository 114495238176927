import React, { useEffect } from "react";
import { PageSpinner } from "components/PageSpinner/PageSpinner";
import { useAuth } from "lib/hooks/useAuth";
import { useRouter } from "lib/hooks/useRouter";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

export const withAnon = <Props extends EmotionJSX.IntrinsicAttributes>(
  Component: (props: Props) => React.ReactElement | null,
): ((props: Props) => React.ReactElement | null) => {
  return (props) => {
    const { user } = useAuth();
    const { navigate } = useRouter();

    useEffect(() => {
      if (user) navigate({ routeName: "Dashboard" });
    }, [user]);

    if (user) return <PageSpinner />;

    return <Component {...props} />;
  };
};
