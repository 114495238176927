import React from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import { Heading, Panel, Text } from "@clearabee/ui-library-native";
import { Layout } from "components/Layout/Layout";
import { LayoutProps } from "components/Layout/Layout.types";
import { styles, ids } from "./LoginLayout.styles";
import { Location } from "./Location";
import { Bed } from "./Bed";
import { Coin } from "./Coin";

interface LoginLayoutProps
  extends Omit<LayoutProps, "leftElement" | "contentWidth"> {
  children: React.ReactNode;
  hideWebHeading?: boolean;
}

const benefits = [
  {
    text: "hwa.components.loginLayout.benefits.first",
    image: <Location />,
  },
  {
    text: "hwa.components.loginLayout.benefits.second",
    image: <Bed />,
  },
  {
    text: "hwa.components.loginLayout.benefits.third",
    image: <Coin />,
  },
] as const;

export const LoginLayout = ({
  children,
  title,
  subtitle,
  hideWebHeading,
  ...layout
}: LoginLayoutProps): React.ReactElement => {
  const [translate] = useTranslation();
  const loginOrSignUpHeader = translate(
    "hwa.components.loginLayout.loginOrSignUpHeader",
  )
    .split(" ")
    .map((word, index) => (
      <Heading
        key={`${word}-${index}`}
        color={
          word === translate("common.or") || word === translate("common.to")
            ? "light"
            : "accent"
        }
      >
        {index ? " " : ""}
        {word}
      </Heading>
    ));

  return (
    <Layout {...layout}>
      <View style={styles.layout}>
        <View style={styles.benefitsView} dataSet={{ media: ids.benefitsView }}>
          <Text styles={{ width: 500 }}>{loginOrSignUpHeader}</Text>

          <View style={styles.benefits}>
            {benefits.map(({ text, image }, index) => (
              <View style={styles.benefit} key={`${index}-${text}`}>
                {image}
                <Text color="light" styles={styles.benefitText}>
                  {translate(text)}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <Panel
          styles={styles.panel}
          dataSet={{ media: ids.panel }}
          shadow={false}
        >
          {!hideWebHeading && (
            <>
              <Text
                color="light"
                styles={{ textAlign: "center" }}
                fontSize="xlarge"
              >
                {title}
              </Text>
              <Text color="light" styles={styles.subtitle} fontSize="xlarge">
                {subtitle}
              </Text>
            </>
          )}

          {children}
        </Panel>
      </View>
    </Layout>
  );
};
